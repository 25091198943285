@import "@Static/css/common";

// theme colours
$color1: getColorValue(1, (29, 57, 203));
$color2: getColorValue(2, (255, 255, 255));

$navbarOpacity: 0.95;
$navbarPrimary: getColorValue(1, (255, 91, 0), $navbarOpacity);
$navbarSecondary: getColorValue(2, (255, 255, 255), $navbarOpacity);

.root {
    box-shadow: 0px 1px 4px 0px #0005;
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 11;

    backdrop-filter: saturate(180%) blur(20px);

    &.loggedIn {
        background-color: $navbarPrimary;
    }

    &.notLoggedIn {
        background-color: $navbarSecondary;
    }
}

.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
}

.subContainer {
    display: flex;
    align-items: center;
}

.leftSubContainer {
    @extend .subContainer;
    margin-right: 2rem;
}
.rightSubContainer {
    @extend .subContainer;
}

.navbarItemColorScheme {
    &.loggedIn {
        background-color: $color2;
        color: $color1;
    }

    &.notLoggedIn {
        background-color: $color1;
        color: $color2;
    }
}

.navbarItemInvertedColorScheme {
    &.notLoggedIn {
        color: $color1;
    }

    &.loggedIn {
        color: $color2;
    }
}

.navbarItem {
    text-decoration: none !important;
    cursor: pointer;
    @extend .navbarItemColorScheme;
}

.hostPageButton {
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
    text-decoration: none !important;
    @extend .navbarItemInvertedColorScheme;
}

.homeIcon {
    display: flex;
    padding: 5px 15px;
    font-size: 1.2rem;
    margin-right: 10px;
    border-radius: 10000000rem;
    @extend .navbarItem;
}

.loginButton {
    cursor: pointer;
    font-size: 16px;
    padding: 5px 15px !important;
    border-radius: 10000000rem;
    @extend .navbarItem;
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    margin: 6px;
    border-radius: 6px;
}

@media only screen and (max-width: 768px) {
    .leftSubContainer {
        margin-right: 8px;
    }
}
